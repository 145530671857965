<template>
    <v-card class="TableCard px-5" :class="{ rounded, 'not-rounded': !rounded }">
        <v-row v-if="title" class="TableCard_Title-wrapper">
            <v-col cols="12" class="TableCard_Title-Banner">
                <span class="TableCard_Title">{{ title }}</span>
                <v-spacer />
                <slot name="menu"></slot>
            </v-col>
        </v-row>
        <v-row class="TableCard_Content">
            <v-col cols="12">
                <slot name="content"></slot>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        rounded: {
            default: true,
            type: Boolean
        }
    }
};
</script>

<style lang="scss">
.rounded {
    border-radius: 10px;
}

div.TableCard.px-5.v-card.v-sheet.theme--light.not-rounded {
    border-radius: 0px !important;
}

.TableCard {
    border: 0px;

    &_Title-Banner {
        padding-top: 0px !important;
    }

    &_Title {
        font-weight: bold;
        color: $dexxter-dark;
    }

    &_Title-Banner {
        display: flex;
    }

    &_Content {
    }

    &_Menu {
    }
}
</style>
